<div class="body__enter">
    <div class="enter">
        <div class="enter__top">

            <div>
                <a href="#" class="enter__topLogo _eko"></a>
            </div>
            <div class="enter__top--right">
                <div class="enter__login"
                     [routerLink]="['../login']">
                    Вход в личный кабинет
                </div>

                <a href="#" class="enter__topLogo _big"></a>
            </div>

        </div>

        <ng-container *ngIf="!registrationData; else dataTpl">
            <form *ngIf="form"
                  class="enter__form _number"
                  [formGroup]="form"
                  (ngSubmit)="onSubmit()">
                <div class="enter__head">
                    <div class="enter__headIcon"></div>
                    <div class="enter__headContent">
                        <p class="enter__headSupport">
                            Регистрация в системе
                        </p>
                    </div>
                </div>
                <div class="enter__fields">
                    <div class="enter__field"
                         [class._file]="isContractNumber"
                         [class._number]="!isContractNumber">
                        <p class="enter__fieldSupport">
                            {{isContractNumber ? 'Введите номер договора или лицевого счета' : 'Введите номер Счета Мосэнергосбыт или мособлеирц'}}
                        </p>
                        <div class="enter__fieldInput">
                            <input type="text"
                                   #input
                                   class="enter__fieldItem"
                                   [class.enter__fieldItem--error]="FC.number?.errors && submitted"
                                   formControlName="number">
                        </div>
                        <ng-container *ngIf="submitted">
                            <ng-container *ngIf="FC.number.errors as e">
                                <div *ngIf="e.required" class="enter__error">
                                    <p>Обязательное поле</p>
                                </div>
                                <div *ngIf="e.symbols" class="enter__error">
                                    Только буквы, цифры, тире и дробь
                                </div>
                                <div *ngIf="e.notFound" class="enter__error">
                                    {{e.notFound}}
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="enter__buttons">
                    <button class="enter__button" type="submit">
                        Далее
                    </button>
                </div>
            </form>
            <span class="enter__support"
                  (click)="skip()">
                {{isContractNumber ? 'У меня нет номера договора и лицевого счета' : 'У меня нет счета в этих организациях'}}
            </span>
        </ng-container>
        <ng-template #dataTpl>

            <ng-container *ngIf="incorrectData; else enterDataTpl">
                <div class="enter__success">
                    <p class="enter__successContent">
                        Проверьте правильность введенного номера счета или договора, либо обратитесь в <a class="enter__successContentLink" href="https://ecopromserv.ru/%d0%ba%d0%be%d0%bd%d1%82%d0%b0%d0%ba%d1%82%d1%8b/">Территориальное подразделение ЭкоПромСервис или на Горячую линию</a>
                    </p>
                </div>
            </ng-container>
            <ng-template #enterDataTpl>
                <ng-container *ngIf="isRegistrationSuccess; else formTpl">
                    <div class="enter__success">
                        <p class="enter__successTitle">
                            Заявка отправлена
                        </p>
                        <p class="enter__successContent">
                            Ваша заявка отправлена. Пожалуйста, ожидайте ответ по электронной почте, указанной в Заявке.
                        </p>
                        <p class="enter__successAction"
                           [routerLink]="['../login']">
                            назад
                        </p>
                    </div>
                </ng-container>
                <ng-template #formTpl>
                    <form class="enter__form" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="enter__head">
                            <div class="enter__headIcon"></div>
                            <div class="enter__headContent">
                                <p class="enter__headSupport">
                                    Регистрация в системе
                                </p>
                                <p class="enter__headTitle">
                                    Проверьте правильность данных
                                </p>
                            </div>
                        </div>
                        <div class="enter__fields">
                            <div class="enter__field _noIcon">
                                <p class="enter__fieldSupport">
                                    Выберите ваш точный адрес из выпадающего меню
                                </p>
                                <div class="enter__fieldInput">
                                    <app-select-field [options]="registrationData.addresses"
                                                      (changeSelection)="onChangeAddress($event)"></app-select-field>
                                </div>
                                <div *ngIf="incorrectAddress"
                                     class="enter__error">
                                    Вы выбрали неправильный адрес
                                </div>
                            </div>
                            <div class="enter__field _noIcon">
                                <p class="enter__fieldSupport">
                                    Номер телефона
                                </p>
                                <div class="enter__fieldInput">
                                    <input type="text"
                                           class="enter__fieldItem"
                                           prefix="+7 "
                                           mask="(000) 000 00 00"
                                           [class.enter__fieldItem--error]="FC.phone.errors && submitted"
                                           formControlName="phone">
                                </div>
                            </div>
                            <div class="enter__field _noIcon">
                                <p class="enter__fieldSupport">
                                    E-mail
                                </p>
                                <div class="enter__fieldInput">
                                    <input type="text"
                                           class="enter__fieldItem"
                                           [class.enter__fieldItem--error]="FC.email.errors && submitted"
                                           formControlName="email">
                                </div>
                            </div>
                        </div>
                        <div class="enter__buttons">
                            <button class="enter__button _half"
                                    type="submit"
                                    [disabled]="form.invalid">
                                Зарегистрироваться
                            </button>
                            <button class="enter__button _half _grey"
                                    type="button"
                                    (click)="cancel()">
                                отмена
                            </button>
                        </div>
                    </form>
                    <span class="enter__support"
                          (click)="incorrectData = true">
                        Неверные данные? Нажмите
                    </span>
                </ng-template>
            </ng-template>

        </ng-template>

        <a href="#" class="enter__symbol"></a>
    </div>
</div>

